import {
	Add,
	ConfirmationNumber,
	ContentCopy,
	Delete,
	WhatsApp,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	TextField,
} from "@mui/material";
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowsProp,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { useEffect, useState } from "react";
import { TicketClass } from "../../components/stepper/tickets-step";
import { Attendee } from "../../hooks/contexts/UseAttendeeContext";
import { makeRequest } from "../../utility/request";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AddNewAttendee } from "./AddNewAttendee";
import { Event } from "../../hooks/contexts/UseEventContext";
// import { randomUUID } from "crypto";

function DeleteButton({
	id,
	setRows,
}: {
	id: string;
	setRows: CallableFunction;
}) {
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => setOpen(false);
	return (
		<Box>
			<Button
				onClick={() => setOpen(true)}
				sx={{
					".MuiButton-startIcon": {
						m: 0,
					},
				}}
				variant="contained"
				color="error"
				startIcon={<Delete />}
			></Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{" Are you sure you want to permanently delete this?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to continue?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							makeRequest({
								endpoint: "/api/attendee/delete",
								data: {
									id: id,
								},

								requestMessage: {
									success: "Was Successfuly Deleted",
									pending: "Loading",
									error: "Something went wrong! 🤯",
								},
								onResolve: (response: any) => {
									setRows((rows: any) =>
										rows?.filter(
											(row: any) => row.id !== id
										)
									);
								},
							});
						}}
					>
						DELETE
					</Button>

					<Button variant="contained" onClick={() => handleClose()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

function DeleteTicketButton({
	id,
	setRows,
}: {
	id: string;
	setRows: CallableFunction;
}) {
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => setOpen(false);
	return (
		<Box>
			<Button
				onClick={() => setOpen(true)}
				sx={{
					".MuiButton-startIcon": {
						m: 0,
					},
				}}
				variant="contained"
				color="error"
				startIcon={<Delete />}
			></Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{" Are you sure you want to permanently delete this?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to continue?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							makeRequest({
								endpoint: "/api/ticket/delete",
								data: {
									id: id,
								},

								requestMessage: {
									success: "Was Successfuly Deleted",
									pending: "Loading",
									error: "Something went wrong! 🤯",
								},
								onResolve: (response: any) => {
									setRows((rows: any) =>
										rows?.filter(
											(row: any) => row.id !== id
										)
									);
								},
							});
						}}
					>
						DELETE
					</Button>

					<Button variant="contained" onClick={() => handleClose()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
export function ManageTickets({
	attendee_id,
	already_reserved,
}: {
	attendee_id: string;
	already_reserved: boolean;
}) {
	return (
		<>
			<Button
				disabled={!already_reserved}
				variant="contained"
				color="success"
				target="_blank"
				href={`/admin/manage/tickets/${attendee_id}`}
				startIcon={<ConfirmationNumber />}
			>
				View
			</Button>
		</>
	);
}

export function ManageAttendees() {
	return (
		<Box>
			<FullFeaturedCrudGrid />;
		</Box>
	);
}

const types = [
	"Class A | فئة أ",
	"Class B | فئة ب",
	"Class C | فئة ج",
	"Class D | فئة د",
];

const typesInverse: { [key: string]: number } = {
	"Class A | فئة أ": 1,
	"Class B | فئة ب": 2,
	"Class C | فئة ج": 3,
	"Class D | فئة د": 4,
};

const paymentTypes = ["Not Paid | غير مدفوع", "Paid | مدفوع", "Invite | مدعو"];

const paymentTypesInverse: { [key: string]: number } = {
	"Not Paid | غير مدفوع": 0,
	"Paid | مدفوع": 1,
	"Invite | مدعو": 2,
};

interface EditToolbarProps {
	setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
	setRowModesModel: (
		newModel: (oldModel: GridRowModesModel) => GridRowModesModel
	) => void;
	setEventId: CallableFunction;
	event_id: number;
}

export function EditToolbar(props: EditToolbarProps) {
	const { setRows, setRowModesModel, setEventId, event_id } = props;
	const handleClick = () => {
		const id = randomId();
		setRows((oldRows) => [
			...oldRows,
			{ id, name: "", age: "", isNew: true },
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
		}));
	};

	const [eventList, setEventList] = useState<Array<Event>>([]);

	useEffect(() => {
		makeRequest({
			endpoint: `/api/event/get_all`,
			data: {},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				setEventList(
					response.data.map(
						(eventObj: any) => new Event({ ...eventObj })
					)
				);
			},
		});
	}, []);

	return (
		<GridToolbarContainer>
			<TextField
				defaultValue={0}
				select
				sx={{ width: "100%" }}
				onChange={(value) => {
					setEventId(value.target.value);
				}}
			>
				<MenuItem value={0}>اختر الحفلة</MenuItem>
				{eventList.map((event) => (
					<MenuItem value={event?.id}>
						{"حفلة " +
							event.name +
							" " +
							event.getDay() +
							" [" +
							event.getDate() +
							"]"}
					</MenuItem>
				))}
			</TextField>
			<Box sx={{ p: "10px 0" }}>
				<AddNewAttendee event_id={event_id} />
			</Box>
		</GridToolbarContainer>
	);
}

export function FullFeaturedCrudGrid() {
	const [rows, setRows] = useState<GridRowsProp>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const [event_id, setEventId] = useState(0);

	const [selectedEvent, setSelectedEvent] = useState<Event>();
	useEffect(() => {
		makeRequest({
			endpoint: `/api/event/get_all`,
			data: { id: event_id },

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				// // console.log(response.data,"$#########")
				setSelectedEvent(new Event({ ...response.data[0] }));
			},
		});
	}, [event_id]);

	// useEffect(() => {
	// 	// // console.log(selectedEvent,"***********")
	// }, [selectedEvent]);
	useEffect(() => {
		// // console.log(event_id);

		makeRequest({
			endpoint: "/api/attendee/get/all",
			data: {
				event_id: event_id,
			},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! 🤯",
			},
			onResolve: (response: any) => {
				setRows(
					response.data.map(
						(row: any) =>
							new Attendee({
								...row,
								ticket_type: types[row.ticket_type - 1],
								payment_status:
									paymentTypes[row.payment_status],
							})
					)
				);
			},
		});
	}, [event_id]);

	const handleRowEditStop: GridEventListener<"rowEditStop"> = (
		params,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleHasPaidClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow!.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false };

		makeRequest({
			endpoint: "/api/attendee/update",
			data: {
				...newRow,
				note: newRow.note ? newRow.note : "",
				ticket_type: typesInverse[newRow.ticket_type],
				payment_status: paymentTypesInverse[newRow.payment_status],
			},

			requestMessage: {
				success: "Was Successfuly Updated",
				pending: "Updating",
				error: "Something went wrong! 🤯",
			},
			onResolve: (response: any) => {
				setRows(
					rows.map((row) => (row.id === newRow.id ? updatedRow : row))
				);
			},
			onCatch: (err: any) => console.log(err),
		});

		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID\nID بالعربي",
			width: 280,
			editable: false,
			headerAlign: "center",
		},
		{
			field: "priority",
			headerName: "Priority\nاسبقية الدفع",
			width: 100,
			align: "center",
			editable: true,
			headerAlign: "center",
		},
		{
			field: "email",
			headerName: "Email\nالبريد الاكتروني",
			minWidth: 250,
			editable: true,
		},
		{
			field: "first_name",
			headerName: "first Name\nاسم الاول",
			// width: 180,
			minWidth: 150,
			editable: true,
		},
		{
			field: "last_name",
			headerName: "Last Name\nاسم العائلة",
			// width: 180,
			minWidth: 150,
			editable: true,
		},
		{
			field: "phone_number",
			headerName: "Phone Number\nرقم التلفون",
			minWidth: 150,
			editable: true,
		},

		{
			field: "number_of_tickets",
			headerName: "Number of Tickets\nعدد التذاكر",
			type: "number",
			width: 150,
			align: "center",

			headerAlign: "center",
			editable: true,
		},
		{
			field: "ticket_type",
			headerName: "Ticket Type\nنوع التذاكر",
			type: "singleSelect",
			width: 150,
			align: "center",

			valueOptions: types,
			headerAlign: "center",
			editable: true,
		},

		{
			field: "payment_status",
			type: "singleSelect",
			valueOptions: paymentTypes,
			align: "center",
			headerAlign: "center",
			headerName: "Payment Status\nحالة الدفع",
			width: 150,
			editable: true,
		},
		{
			field: "already_reserved",
			headerName: "Has Reserved Seats\nاتم حجز المقاعد",
			type: "boolean",
			width: 150,
			editable: false,
		},
		{
			field: "button1",
			headerName: "WhatsApp Message\nرسالة الوااتسسس",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 500,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				const seatReservationWhatsAppMessage = encodeURIComponent(`
مرحبا،
احجزوا مقاعدكم من الرابط التالي، بعدها رح تطلعلكم تذاكركم بنتمنى تخلوها معكم وتورجونا اياهم لما تيجوا عالمسرح

					
الرابط:

${window.document.location.origin}/${row.id}

مستنيين نشوفكم 💙
					`);
				const requestPaymentWhatsAppMessage = encodeURIComponent(`
مرحبا، أنا عبدالله من "${
					window.document.location.origin.toLocaleLowerCase() ===
					"https://pop.watarelsharq.com"
						? "وترPop"
						: "وتر الشرق"
				}"

شكرا لاهتمامكم بالحضور، مستنيينكم لحضور أمسيتنا *"${
					selectedEvent?.name
				}"* واللي رح تكون يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*

لاستكمال الحجز، لو سمحتوا اختاروا مقاعدكم من هذا الرابط الإلكتروني.

${window.document.location.origin}/${row.id}

بعدين بتقدرو تحولو قيمة التذاكر عن طريق CliQ على *${
	window.document.location.origin.toLocaleLowerCase() ===
	"https://pop.watarelsharq.com"
		? "WatarPop"
		: "WatarSharq"
}* 

مع ضرورة إرسال رسالة لتأكيد التحويل خلال اربع ساعات من استلام هذه الرسالة، والا سيتم الغاء حجز المقاعد، واختيارهم مجددا.

Class A | JD 20
Class B | JD 15
Class C | JD 12
Class D | JD 10


بنستناكم يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*


${ selectedEvent?.theater_id === 1 ?
"موقع مسرح جمعية الثقافة والتعليم الأرثوذكسية:\n\nhttps://goo.gl/maps/EoMSjwdP51tugQG86\n\n *يوجد مواقف للسيارات داخل المسرح.*":
"موقع مسرح مدرسة الاكاديمية الدولية عمان:\n\nالموقع للدخول بالسيارة:\n\nhttps://maps.app.goo.gl/btFnYPhZRsKRJ2i37\n\nالموقع للدخول مشي:\n\nhttps://maps.app.goo.gl/kQrtcpaGfaCB3KyJ7"
}



رح يبدأ العرض الساعة 8:00 مساء، بنفضل تيجوا قبل بوقت لإنه أبواب المسرح ستغلق الساعة 8:15 وعشان تلحقوا العرض من أوله.
`);

				const changedTheaterWhatsAppMessage = encodeURIComponent(`
كل عام وانتم بخير💙

تلبية لرغبة الجميع، تم تغيير مكان وزمان أمسية وردة لتكون على مسرح جمعية الثقافة والتعليم الأرثوذكسية بدلا من قصر الثقافة واللي رح تكون يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*  الساعة 8 مساءً 

بنتمنى ترجعوا تختاروا المقاعد من نفس الرابط الإلكتروني،

${window.document.location.origin}/${row.id}

وبعدها ابعتولنا كليك نحوللكم عليه فرق سعر التذاكر (إن وجد).

علما انه الاسعار هي:

Class A | JD 20
Class B | JD 15
Class C | JD 12
Class D | JD 10


بنستناكم يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*


موقع مسرح جمعية الثقافة والتعليم الأرثوذكسية:
https://goo.gl/maps/EoMSjwdP51tugQG86


*يوجد مواقف للسيارات داخل المسرح.*

رح يبدأ العرض الساعة 8:00 مساء، بنفضل تيجوا قبل بوقت لإنه أبواب المسرح ستغلق الساعة 8:15 وعشان تلحقوا العرض من أوله.
`);

				const requestPaymentAfterPostponingWhatsAppMessage =
					encodeURIComponent(`
مرحبا، أنا عبدالله من "وتر الشرق"


بعد تأجيل أمسية وردة لأول خميس وجمعة من شهر *${
						selectedEvent!.event_date.getMonth() + 0
					}* إلى شهر *${
						selectedEvent!.event_date.getMonth() + 1
					}*، حابين نتأكد إذا ما زلتوا مهتمين تحضروا الأمسية، اللي رح تكون يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*


لاستكمال الحجز، لو سمحتوا اختاروا مقاعدكم من هذا الرابط الإلكتروني.

${window.document.location.origin}/${row.id}

بعدين بتقدرو تحولو قيمة التذاكر عن طريق CliQ أو زين كاش على الرقم 00962790089586 

مع ضرورة إرسال رسالة لتأكيد التحويل خلال اربع ساعات من استلام هذه الرسالة، والا سيتم الغاء حجز المقاعد، واختيارهم مجددا.

Class A | JD 15
Class B | JD 12
Class C | JD 10
Class D | JD 10

بنستناكم يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*

الموقع للدخول بالسيارة:
https://maps.app.goo.gl/btFnYPhZRsKRJ2i37?g_st=ic

الموقع للدخول مشي:
https://maps.app.goo.gl/kQrtcpaGfaCB3KyJ7?g_st=ic

رح يبدأ العرض الساعة 8:00 مساء، بنفضل تيجوا قبل بوقت لإنه أبواب المسرح ستغلق الساعة 8:15 وعشان تلحقوا العرض من أوله.
`);

				const feedbackWhatsAppMessage = encodeURIComponent(
					`
شكرا كتير لحضوركم معنا الأمسية
بنتمنى تعبوا الاستبيان في الرابط، عشان نتأكد ان انكم تكونوا راضيين في كل مرة

https://forms.gle/LHZgE1WHdBeQKNseA
`
				);

				const PostPoneEventWhatsAppMessage = encodeURIComponent(
					`
مرحبا،
بدنا نأكد عليكم أنه حفلة وردة تأجلت حتى إشعار أخرا نظرا للظروف القائمة، كل التذاكر رح نعتمدها للموعد الجديد ونخبركم فيه اول ما نعتمده. 
شكرا لتفهمكم، ونتمنى السلامة لأهلنا في غزة
`
				);

				const requestPaymentWhatsAppMessageAfterTransformation =
					encodeURIComponent(`
مرحبا، أنا عبدالله من "وتر الشرق"


شكرا لاهتمامكم بالحضور،

*خلصت مقاعد حفلة الخميس وعملنالكم حفلة الجمعة وحولناكم عليها*

مستنيينكم بكل حماس لحضور أمسيتنا الثانية في شهر ${
						selectedEvent!.event_date.getMonth() + 1
					} واللي رح تكون يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}* 😍

لاستكمال الحجز، لو سمحتوا اختاروا مقاعدكم من هذا الرابط الإلكتروني.

${window.document.location.origin}/${row.id}

بعدين بتقدرو تحولو قيمة التذاكر عن طريق CliQ أو زين كاش على الرقم 00962790089586 

مع ضرورة إرسال رسالة لتأكيد التحويل خلال اربع ساعات من استلام هذه الرسالة، والا سيتم الغاء حجز المقاعد، واختيارهم مجددا.

Class A | JD 15
Class B | JD 12
Class C | JD 10
Class D | JD 10

بنستناكم يوم *${selectedEvent?.getDay()} ${selectedEvent?.getDate()}*
موقع المسرح:
https://goo.gl/maps/EoMSjwdP51tugQG86

رح يبدأ العرض الساعة 8:00 مساء، بنفضل تيجوا قبل بوقت لإنه أبواب المسرح ستغلق الساعة 8:15 وعشان تلحقوا العرض من أوله.
`);

				return (
					<Box>
						<Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${requestPaymentWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							sx={{ m: 1 }}
							color="success"
							startIcon={<WhatsApp />}
							disabled={row.already_reserved}
						>
							الدفع
						</Button>
						{/* <Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${changedTheaterWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							sx={{ m: 1 }}
							color="success"
							startIcon={<WhatsApp />}
							disabled={row.already_reserved}
						>
							تغيير المسرح
						</Button> */}
						{/* <Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${requestPaymentWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							sx={{ m: 1 }}
							color="success"
							startIcon={<WhatsApp />}
							disabled={row.already_reserved}
						>
							الدفع
						</Button> */}
						{/* <Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${seatReservationWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							color="success"
							sx={{ m: 1 }}
							startIcon={<WhatsApp />}
							disabled={row.already_reserved}
						>
							الحجز
						</Button> */}
						<Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${feedbackWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							color="success"
							sx={{ m: 1 }}
							startIcon={<WhatsApp />}
							// disabled={row.already_reserved}
						>
							Feedback
						</Button>

						{/* <Button
							href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${PostPoneEventWhatsAppMessage}`}
							target="_blank"
							variant="contained"
							color="success"
							sx={{ m: 1 }}
							startIcon={<WhatsApp />}
							// disabled={row.already_reserved}
						>
							تأجيل الحفلة
						</Button> */}
					</Box>
				);
			},
		},
		{
			field: "ticket_link",
			type: "actions",
			headerName: "Link\nرابط الحجز",
			width: 50,
			cellClassName: "actions",
			getActions: ({ id }) => {
				return [
					<GridActionsCellItem
						icon={<ContentCopy />}
						label="Edit"
						className="textPrimary"
						onClick={() => {
							navigator.clipboard.writeText(
								`${window.document.location.origin}/${id}`
							);
							toast.success("Copied!", {
								position: "top-right",
								autoClose: 2000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "light",
							});
						}}
						color="inherit"
					/>,
				];
			},
		},
		{
			field: "button2",
			headerName: "View tickets\nرؤية التذاكر",
			type: "actions",
			headerAlign: "center",
			align: "center",
			width: 150,
			editable: false,
			renderCell: ({ row }) => (
				<ManageTickets
					already_reserved={row.already_reserved}
					attendee_id={row.id}
				/>
			),
		},
		{
			field: "note",
			headerName: "Notes\nملاحظات",
			width: 150,

			editable: true,
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions\nActions بالعربي",
			width: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={handleEditClick(id)}
						color="inherit"
					/>,
				];
			},
		},
		{
			field: "button3",
			headerName: "Delete\nحذف",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				return <DeleteButton id={row.id} setRows={setRows} />;
			},
		},
	];

	return (
		<DataGrid
			sx={{
				bgcolor: "white",
				mt: "-5px",

				".MuiDataGrid-columnHeaderTitle": {
					whiteSpace: "pre-wrap",
					lineHeight: 1.5,
				},

				".MuiDataGrid-main": {
					margin: { xs: "0", md: "0 20px" },
				},

				".MuiDataGrid-row:has(.MuiDataGrid-cell--editing) .actions": {
					bgcolor: " #ffffff",
					position: "sticky",
					right: 0,
					boxShadow: "-3px 0px 1px 0px #b3b3b36b",
				},

				".MuiDataGrid-virtualScroller": {
					height: "calc(100vh - 260px)",
				},
				".MuiDataGrid-actionsCell": {
					gridGap: "20px",
				},
			}}
			rows={rows}
			columns={columns}
			editMode="row"
			getRowHeight={() => "auto"}
			pageSizeOptions={[10, 20, 50, 100]}
			initialState={{
				pagination: {
					paginationModel: {
						pageSize: 100,
					},
				},
				columns: {
					columnVisibilityModel: {
						id: false,
						email: false,
					},
				},
			}}
			rowModesModel={rowModesModel}
			onRowModesModelChange={handleRowModesModelChange}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			slots={{
				toolbar: EditToolbar,
			}}
			slotProps={{
				toolbar: {
					setRows,
					setRowModesModel,
					setEventId,
					event_id,
				},
			}}
		/>
	);
}

export function ManageAttendeeTicket() {
	let { attendee_id } = useParams();

	return <ManageTicketsGrid attendee_id={attendee_id!.toString()} />;
}

export function ManageTicketsGrid({ attendee_id }: { attendee_id: string }) {
	const [rows, setRows] = useState<GridRowsProp>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const types = [
		"Class A | فئة أ",
		"Class B | فئة ب",
		"Class C | فئة ج",
		"Class D | فئة د",
	];

	const typesInverse: { [key: string]: number } = {
		"Class A | فئة أ": 1,
		"Class B | فئة ب": 2,
		"Class C | فئة ج": 3,
		"Class D | فئة د": 4,
	};

	useEffect(() => {
		makeRequest({
			endpoint: `/api/ticket/get`,
			data: {
				attendee_id: attendee_id.toString(),
			},

			requestMessage: {
				pending: "Promise is pending",
				success: "Promise resolved 👌",
				error: "Promise rejected 🤯",
			},
			onResolve: (response: any) => {
				// // console.log(response.data);
				setRows(
					response.data.map((row: any, index: number) => {
						// // console.log(row);
						return new TicketClass({
							...row,
							type: types[row.type - 1],
						});
					})
				);
			},
		});
	}, []);

	const handleRowEditStop: GridEventListener<"rowEditStop"> = (
		params,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleHasPaidClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow!.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false };

		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID\nID بالعربي",
			width: 280,
			editable: false,
			headerAlign: "center",
		},
		{
			field: "type",
			headerName: "Ticket Type\nنوع التذاكر",
			type: "singleSelect",
			width: 150,
			align: "center",

			valueOptions: types,
			headerAlign: "center",
			editable: true,
		},
		{
			field: "seat_row",
			headerName: "Row\nالصف",
			type: "text",

			minWidth: 250,
			editable: true,
		},
		{
			field: "seat_column",
			headerName: "Column\nالعمود",
			// width: 180,
			type: "text",

			minWidth: 150,
			editable: true,
		},

		{
			field: "actions",
			type: "actions",
			headerName: "Actions\nActions بالعربي",
			width: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={handleEditClick(id)}
						color="inherit"
					/>,
				];
			},
		},
		{
			field: "button3",
			headerName: "Delete\nحذف",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				return <DeleteTicketButton id={row.id} setRows={setRows} />;
			},
		},
	];

	return (
		<DataGrid
			key={"ticket" + attendee_id}
			sx={{
				bgcolor: "white",
				mt: "-5px",

				".MuiDataGrid-columnHeaderTitle": {
					whiteSpace: "pre-wrap",
					lineHeight: 1.5,
				},

				".MuiDataGrid-main": {
					margin: { xs: "0", md: "0 20px" },
				},

				".MuiDataGrid-row:has(.MuiDataGrid-cell--editing) .actions": {
					bgcolor: " #ffffff",
					position: "sticky",
					right: 0,
					boxShadow: "-3px 0px 1px 0px #b3b3b36b",
				},

				".MuiDataGrid-virtualScroller": {
					height: "calc(100vh - 230px)",
				},
				".MuiDataGrid-actionsCell": {
					gridGap: "20px",
				},
			}}
			rows={rows}
			columns={columns}
			editMode="row"
			// getRowHeight={() => "auto"}
			pageSizeOptions={[10, 20, 50, 100, 500]}
			initialState={{
				pagination: {
					paginationModel: {
						pageSize: 20,
					},
				},
				columns: {
					columnVisibilityModel: {
						id: false,
						email: false,
					},
				},
			}}
			rowModesModel={rowModesModel}
			onRowModesModelChange={handleRowModesModelChange}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			slots={{
				toolbar: EditToolbar,
			}}
			slotProps={{
				toolbar: { setRows, setRowModesModel },
			}}
		/>
	);
}
